/* 评论 */
.comments-sec {
  width: 100%;
  max-width: 1156px;
}
.comments-list {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.comments-list.compact {
  gap: 10px;
}
.comments-tool {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.comments-list-item {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.comments-list-item.clickable {
  padding: 12px;
  border-radius: 5px;
}
.comments-list-item.clickable:hover {
  background: #f0f0f0;
  cursor: pointer;
}

.avater {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 10px;
  background-size: cover;
  img{
    object-fit: contain;
    width: 56px;
    height: 56px;
  }
}

.avater.small {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
}
.avater-1 {
  // background-image: url("../assets/images/avaters/avater-1.png");
}
.avater-2 {
  background-image: url("../assets/images/avaters/avater-2.png");
}
.avater-3 {
  background-image: url("../assets/images/avaters/avater-3.png");
}
.comments-wrap {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 14px;
}
.comments-info-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.comments-info-wrap p {
  color: var(--primary-txt-color);
}
.comments-info-wrap i {
  margin-left: 16px;
  color: var(--secondary-txt-color);
}
.comments-info-wrap button {
  color: var(--secondary-txt-color);
}
.comment-btn {
  color: var(--secondary-txt-color);
  border: 1px solid #f0f0f0;
  height: 32px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.comments-content {
  font-size: 16px;
  color: var(--primary-txt-color);
  line-height: 140%;
}

.comments-tool-bar {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.comments-tool-bar > div {
  display: flex;
  flex-direction: row;
  color: var(--secondary-txt-color);
}
.comments-thunmb-btn {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.comments-thunmb-btn :hover {
  opacity: 0.8;
}

.icon-delete-small {
  background-image: url("../assets/icons/icon-delete-small.svg");
}
.icon-report-small {
  background-image: url("../assets/icons/icon-report-small.svg");
}
.icon-thumbs-up {
  background-image: url("../assets/icons/icon-comments-thumbup.svg");
}
.icon-thumbs-down {
  background-image: url("../assets/icons/icon-comments-thumbdown.svg");
}
.icon-thumbs-up.active {
  background-image: url("../assets/icons/icon-comments-thumbup-active.svg");
}
.icon-thumbs-down.active {
  background-image: url("../assets/icons/icon-comments-thumbdown-active.svg");
}
.icon-rolldown {
  background-image: url("../assets/icons/icon-rolldown.svg");
}

.reply-btn {
  position: relative;
}
.reply-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 10px;
  color: #fff;
  padding: 2px 5px;
  border-radius: 6px 6px 6px 2px;
  background: var(--alert-red);
}
@media only screen and (max-width: 600px) {
  .comments-sec {
    box-sizing: border-box;
    padding: 0 16px;
  }
  .comments-list {
  }
  .avater {
    width: 42px;
    height: 42px;
    border-radius: 5px;
  }
  .comments-info-wrap i {
    font-size: 12px;
  }
  .comments-tool-bar {
    justify-content: flex-start;
  }
  .comments-tool-bar button {
    font-size: 0;
    gap: 0;
  }
}
