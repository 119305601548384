*,
*::before,
*::after {
  box-sizing: content-box;
}

.error-toast {
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 100;
}

body,
html,
#root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

* ::-webkit-scrollbar {
  width: 0 !important;
}

:root {
  --main-color: #f35828;
  --primary-txt-color: #333;
  --secondary-txt-color: #999;
  --discount-green: #3ec91b;
  --main-color-hover: #ffceb3;
  --alert-red: #b70000;
}

body.no-transitions * {
  transition: none !important;
}
.no-transitions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}
i {
  font-style: normal;
}
li {
  list-style: none;
}
ul,
body {
  font-family: "Noto Sans", "Segoe UI", Arial, sans-serif, "Microsoft YaHei",
    "PingFang SC", "Microsoft JhengHei", "PingFang TC", "system-ui";
  padding: 0;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

textarea {
  border: none;
  background: transparent;
}
textarea::placeholder {
  color: var(--secondary-txt-color);
}
textarea:focus {
  outline: none;
}

button {
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  transition: opacity ease-out 0.1s;
}
button:hover {
  opacity: 0.8;
}
/* 弱网按钮样式 */
button.weak-network {
  position: relative;
  color: transparent !important;
}

button.weak-network::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 20px;
  height: 20px;
  background-image: url("../assets/icons/icon-loading-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  animation: show-loading 1.5s infinite linear;
  transition: opacity 0.2s linear;
}

/* 定义动画关键帧 */
@keyframes show-loading {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  99% {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
}

input,
input:focus {
  outline: none;
  border: none;
}
input {
  color: #333;
}
input::placeholder {
  color: #aaa;
}

.required > p::before {
  content: "* ";
  color: red;
  font-weight: bold;
}
.unit {
  color: var(--secondary-txt-color);
}
a {
  text-decoration: none;
}

iframe {
  border: none;
  outline: none;
}

/* 挂边按钮组件 */
.side-bar {
  position: fixed;
  right: 30px;
  top: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 800;
}
.side-bar > div {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--main-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-out 0.2s;
}
.side-bar > div:hover {
  transform: scale(1.1);
}
.side-bar > div > div {
  width: 20px;
  height: 20px;
}

#toTopBtn {
  visibility: hidden;
  opacity: 0;
}
#toTopBtn.show {
  visibility: visible;
  opacity: 1;
}

/* 二级导航组件 */
.secondery-nav {
  height: 48px;
  max-width: 1156px;
  display: flex;
  flex-direction: row;
  /* overflow-x: scroll; */
  /* gap: 30px; */
  &.fixed {
    position: fixed;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.cata-btn {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cata-btn:hover {
  opacity: 0.6;
}
.icon-list {
  width: 20px;
  height: 20px;
}
/* 目录菜单组件 */
.menu-container {
  position: fixed;
  left: -300px;
  top: 0;
  width: 300px;
  height: 100vh;
  background: #fff;
  z-index: 500;
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  visibility: hidden;
  transition: all 0.2s linear;
}
.menu-container.show {
  transform: translateX(100%);
  visibility: visible;
}

.btn-back {
  display: none;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
}
.btn-back.show {
  display: block;
}

.menu-title {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-btn {
  cursor: pointer;
}

.icon-close {
  width: 20px;
  height: 20px;
}

.breadcrumbs li {
  display: inline-block;
  margin-right: 0.5rem;
}

.breadcrumbs li:not(:last-child)::after {
  content: ">";
  margin-left: 0.25rem;
}

.icon {
  width: 20px;
  height: 20px;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7f7;
}
.main-container {
  position: relative;
  width: 1156px;
  display: flex;
  flex-direction: column;
  /* padding: 12px 0; */
}

/* carousel */
.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 372px;
  border-radius: 10px;
  cursor: pointer;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  flex-shrink: 0;
  height: 100%;
  object-fit: cover;
}
.carousel-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  .carousel-button {
    z-index: 1;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 36px;
    cursor: pointer;
    outline: none;

    transform: translateY(-50%);
  }
}

#prevBtn {
  left: 30px;
}

#nextBtn {
  right: 30px;
}

/* 二级导航  */
.secondery-nav-tab {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.secondery-nav-tab > p {
  flex-shrink: 0;
  line-height: 48px;
  padding: 0 15px;
  color: var(--secondary-txt-color);
  cursor: pointer;
  transition: opacity 0.1s linear;
}
.secondery-nav-tab > p:hover {
  opacity: 0.8;
}
.secondery-nav-tab > p.selected {
  color: var(--main-color);
}
.secondery-nav-tab > p.selected:hover {
  opacity: 1;
}

.user-select-none,
div,
img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
p {
  margin: 0;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

/* 弹窗样式 */
.pop-up-shadow {
  display: none;
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vw;
  background: rgba(0, 0, 0, 0.15);
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.pop-up-shadow.show {
  display: flex;
}
.popup-panel {
  position: relative;
  height: 100px;
  width: 300px;
  border-radius: 20px;
  background: #fff;
}

.txt-tip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 24px;
  gap: 40px;
}
.txt-tip p {
  font-size: 18px;
}
.popup-panel .icon-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.popup-panel .btn {
  flex: 0 0 0;
  display: flex;
  font-size: 14px;
  padding: 8px 32px;
  border-radius: 5px;
  background: var(--main-color);
  color: #fff;
  cursor: pointer;
}

/* 弹窗 */
.modal-container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  /* visibility: hidden; */
  display: none;
  transition: opacity 0.3s, visibility 0.3s;
}

.modal-container.show {
  display: flex;
}
.modal-wrap {
  position: relative;

  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.modal-content {
  width: 100%;
  height: 100%;
}
.popup-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
/* 导航样式 */

.nav-btn-list {
  display: flex;
  gap: 14px;
}
.nav-btn-list > div {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 6px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s linear;
}

.nav-btn-list > .nav-avater {
  position: relative;
  width: 36px;
  height: 36px;
  margin-top: 3px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 18px;
  background-image: url("../assets/images/avaters/avater-1.png");
  background-size: cover;
  background-position: center;
}
.nav-btn-list > .nav-avater:hover {
  opacity: 1;
}
.logout-btn {
  color: var(--alert-red);
}
/* 用户登录后头像菜单 */
.user-func-list {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  background: #f7f7f7;
  gap: 1px;
  transform: translateY(100%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 500;
  overflow: hidden;
}
.nav-avater:hover .user-func-list {
  display: flex;
}
.user-func-list > a,
.user-func-list > div {
  padding: 16px;
  width: 200px;
  font-size: 14px;
  display: flex;

  background: #fff;
  color: var(--primary-txt-color);
  cursor: pointer;
}
.user-func-list > a:hover,
.user-func-list > div:hover {
  background: #f0f0f0;
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5px 5px;
  background: #fff;
  color: var(--main-color);
  font-size: 10px;
  border-radius: 10px 10px 10px 1px;
}
.nav-btn-list > div:hover {
  opacity: 0.8;
}
.nav-btn-list p {
  width: auto;
  font-size: 10px;
  text-align: center;
  color: white;
  white-space: nowrap;
}
.nav {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--main-color);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2px;
  gap: 20px;
  padding-bottom: 20px;
}
.disclaimers {
  font-size: 10px;
  font-style: italic;
  background-color: var(--main-color);
  color: white;
  text-align: center;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  background-color: var(--main-color);
  width: 100%;
  max-width: 1156px;
  align-items: center;
}
.logo {
  width: 202px;
  height: 48px;
  background-image: url("../assets/icons/itopic-logo.svg");
  background-size: contain;
  cursor: pointer;
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: text;
  position: relative;
  height: 48px;
  border-radius: 24px;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.2);
}

/* 搜索记录 */
.search-input-wrap {
  display: flex;
  flex-direction: row;
  height: 48px;
  box-sizing: border-box;
  padding: 16px;
  align-items: center;
  gap: 8px;
}
.search-input-wrap > input {
  font-size: 14px;
  flex-grow: 1;
  color: var(--primary-txt-color);
}
.search-record-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  background: #fff;
  z-index: 500;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding-bottom: 20px;
}
.search-record-container.show {
  display: flex;
}
.record-list {
  max-height: 260px;
  overflow-y: auto;
}

.record-list > li {
  padding: 0 16px;
  color: var(--secondary-txt-color);
  font-size: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  transition: padding linear 0.2s, font-size linear 0.2s;
}
.show .record-list > li {
  padding: 16px;
  font-size: 14px;
}

.record-list > li:hover {
  background: #f0f0f0;
}

.search-record-btn {
  opacity: 0;
}
.record-list > li:hover .search-record-btn {
  opacity: 1;
}
.footer-container {
  display: flex;
  position: fixed;
  justify-content: space-around;
  width: 100%;
  visibility: hidden;
  border: 1px solid #ededed;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  &.show {
    visibility: visible;
    transform: translateY(-1%);
    opacity: 1;
    position: fixed;
    bottom: 0;
  }
  &.showBlock {
    visibility: visible;
    transform: translateY(-1%);
    opacity: 1;
    position: static;
  }
  .footer {
    box-sizing: border-box;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 0 20px;
    transition: transform 0.2s, opacity 0.2s;
    max-width: 1156px;
    overflow-x: auto;
    align-items: center;
    gap: 12px;
    .ant-btn{
      padding: 16px 0;
    }
    button {
      color: var(--secondary-txt-color);
      &:hover {
        color: var(--main-color) !important;
      }
    }
    a {
      padding: 16px 0;
      color: var(--secondary-txt-color);
      transition: color linear 0.1s;
      white-space: nowrap;
      &:hover {
        color: var(--main-color);
      }
    }
  }
}
.layout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.side-container {
  position: sticky;
  top: 0;
  width: 0;
  opacity: 0;
  height: 300px;
  background: red;
  transition: opacity 0.2s linear, width 0.2s linear;
}
.side-container.show {
  width: 300px;
  opacity: 1;
}

#sideContainer > iframe {
  width: 100%;
  height: 100%;
}
.right-floating-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background: #fff;
}
#directory-container {
  overflow: auto;
}
#catagoryList {
  display: flex;
  width: 100%;
  flex-direction: column;
}

#catagoryList > li {
  box-sizing: border-box;
  padding: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  transition: all 0.1s linear;
}
.cata-title {
  flex-grow: 1;
  line-height: 36px;
  padding: 0 8px;
  font-size: 16px;
  border-radius: 10px;
  color: var(--primary-txt-color);
  cursor: pointer;
}
.cata-title:hover {
  background: var(--main-color);
  color: #fff;
}
.cata-title {
  &.selected {
    background: var(--main-color);
    color: #fff;
  }
}
.cata-list-btn {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cata-list-btn:hover {
  background: var(--main-color);
}
#catagoryList .btn-list {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.category-list .subcategories {
  display: none;
  margin-left: 1rem;
}

.category-list .subcategories li {
  margin-top: 0.25rem;
}

.category-list .subcategories li a {
  padding-left: 1.5rem;
}

.category-list .subcategories.open {
  display: block;
}
/* 右侧浮窗 */
.right-floating-iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* 商品浮窗 */
.hovering-panel {
  width: 568px;
  height: 352px;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  pointer-events: none;
  z-index: 500;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 140%;
  color: var(--primary-txt-color);
}
.hovering-panel p {
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0;
}
.hovering-panel.left::before {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 20px 15px 0;
  border-color: transparent #fff transparent transparent;
}

.hovering-panel.right::after {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  right: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 20px;
  border-color: transparent transparent transparent #fff;
}

.mobile {
  display: none;
}

/* 下啦菜单样式 */
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-title {
  margin-bottom: 10px;
  font-size: 12px;
  color: var(--primary-txt-color);
}

.dropdown-toggle {
  text-align: left;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  padding: 0 12px;
  height: 36px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.dropdown-menu li {
  padding: 12px 16px;
  font-size: 14px;
  color: var(--secondary-txt-color);
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.selected-option {
  margin-top: 10px;
  font-weight: bold;
}

.markdownMessage {
  margin: 20px auto 80px;
  max-width: 1156px;
  color: rgb(31, 35, 41);
  font-family: LarkHackSafariFont, LarkEmojiFon;
  font-feature-settings: normal;
  font-variant-ligatures: no-common-ligatures;
  font-variant-numeric: normal;
  line-height: 30px;
 
}


@media only screen and (max-width: 600px) {
  html {
    overflow-x: hidden;
    width: 100vw;
  }
  body {
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    width: 100%;
  }
  .layout-container {
    gap: 0;
  }
  .main-container {
    width: 100vw;
  }

  .secondery-nav-tab {
    width: 100%;
    overflow-x: scroll;
  }
  .carousel-inner {
    width: 100%;
  }
  .disclaimers {
    /* font-size: 9px; */
    text-align: center;
    opacity: 0.5;
  }
  .logo {
    display: block;
    width: 150px;
    height: 30px;
    background-size: cover;
  }
  .carousel-container {
    height: 170px;
    border-radius: 0;
  }
  .carousel-inner {
    height: 100%;
  }
  .side-bar {
    display: none;
  }
  .nav-btn-list {
    display: none;
  }
  .search-container {
    height: 40px;
  }
  .nav-content {
    box-sizing: border-box;
    gap: 10px;
    padding: 0 12px;
  }
  .mobile {
    display: flex;
  }
  .footer{
    .ant-btn{
      padding: 16px 0 0!important;
    }
   
    a {
      padding: 16px 0 0 !important;
    }
  }
}

.search-container{
  .ant-select-selector {
    width: 100%;
  }
  .ant-input-search-button{
    background: none;
    &:hover{
      background: none !important;
    }
  }
  .ant-input-group-addon{
    background: none;
    &:hover{
      background: none;
    }
  }
  .ant-input-search .ant-input-group .ant-input-affix-wrapper{
    border-start-start-radius: 60px;
    border-end-start-radius: 60px;
    border: none;
    background: none;
  }
  .ant-input-outlined:focus-within{
    box-shadow: none !important;
  }

}

.item{
  display: flex;
  gap: 8px;
 }

 #directoryContainer{
  overflow: auto;
  z-index: 999;
 }