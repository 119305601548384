/* products */
.products-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    padding: 20px 0;

  }
  .panel {
    background: white;
    position: relative;
    flex: 0 0 176px;
    height: 352px;
    border-radius: 10px;
    display: flex;
  
    flex-direction: column;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .panel-mobile-right {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 12px;
  }
  
  .panel:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .pic-wrap {
    width: 176px;
    height: 176px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    position: relative;
  }
  
  .feature-icon {
    position: absolute;
    right: 3px;
    bottom: 3px;
    box-sizing: border-box;
    /* width: 32px; */
    height: 32px;
    background: white;
    border-radius: 6px 6px 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    gap: 0;
    transition: gap linear 0.2s;
  }
  .feature-icon > div {
    width: 20px;
    height: 20px;
  }
  .feature-detail {
    visibility: hidden;
  
    font-size: 0px;
    font-weight: 600;
    transition: all linear 0.2s;
    color: var(--primary-txt-color);
  }
  .panel:hover .feature-detail {
    visibility: visible;
    font-size: 12px;
  }
  .panel:hover .feature-icon {
    gap: 6px;
  }
  
  .pic-wrap img {
    width: 100%;
  }
  .price-trend {
    width: 32px;
    height: 20px;
    background-image: url("../assets/images/pic-trend.svg");
  }
  .goods-title {
    flex: 1 0 0;
    font-size: 12px;
    color: var(--primary-txt-color);
    padding: 12px;
  }
  .price-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 3px;
  }
  
  .discounted-price {
    font-size: 18px;
    font-weight: 600;
    color: #3ec91b;
  }
  .original-price {
    font-size: 12px;
    color: #999;
  
    text-decoration-line: line-through;
    text-decoration-thickness: 1px;
    padding-bottom: 3px;
  }
  .icon-doller {
    background-image: url("../assets/icons/icon-doller.svg");
    background-repeat: no-repeat;
    width: 9px;
    height: 14px;
    padding-bottom: 6px;
  }
  /* .text-strikethrough {
      text-decoration-line: line-through;
      text-decoration-color: red; 
      
    } */
  .bottom-bar {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
  .bottom-bar.loose {
    gap: 5px;
  }
  .tool-wrap {
    display: flex;
    align-items: center;
    gap: 14px;
    flex-direction: row;
    justify-content: space-between;
  }
  .goods-btn-list {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
  .icon-btn {
    width: 20px;
    height: 20px;
  }
  
  .icon-remove:hover,
  .icon-noLikeGoods:hover,
  .icon-shareGoods:hover {
    opacity: 0.4;
  }
  /* 价格趋势曲线 */
  .trend-curve {
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
  }
  
  /* 标签 */
  .red-tip {
    padding: 5px;
    z-index: 200;
    position: absolute;
    top: 43px;
    left: -4px;
    border-radius: 3px 3px 3px 0;
    background: #ff0000;
    color: white;
    font-size: 14px;
  }
  .red-tip::after {
    content: "";
    background: url("../assets/images/pic-red-tip-after.svg");
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 4px;
    height: 4px;
  }
  .panel.big {
    box-sizing: border-box;
    flex: 0 0 372px;
    padding: 12px 12px 6px 12px;
    justify-content: space-between;
  }
  .big-panel-title {
    font-size: 16px;
    color: var(--primary-txt-color);
  }
  .big-goods-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
  }
  .spic-wrap {
    width: 169px;
    height: 130px;
    border-radius: 5px;
    overflow: hidden;

  }
  .spic-wrap img {
    width: 169px;
    height: 130px;
    transition: transform linear 0.2s;
    object-fit: contain;
  }
  .spic-wrap:hover img {
    transform: scale(1.1);
  }
  .spic-name {
    color: var(--primary-txt-color);
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    width: 150px;
  }
  .big-panel-detail {
    font-size: 12px;
    line-height: 24px;
    color: #448aa9;
    cursor: pointer;
    transition: opacity linear 0.2s;
  }
  .big-panel-detail:hover {
    opacity: 0.6;
  }
  
  /* 加载loading */
  .hidden {
    opacity: 0;
    height: 0;
  }
  #loadingIndicator {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    height: 100px;
    transition: opacity linear 0.2s;
  }
  
  .loading-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ccc;
    animation: loadingAnimation 1s linear infinite;
  }
  .loading-circle:nth-child(1) {
    animation-delay: 0.3s;
  }
  .loading-circle:nth-child(2) {
    animation-delay: 0.6s;
  }
  
  @keyframes loadingAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  #datafinishedTip {
    width: 100%;
    text-align: center;
    color: #ddd;
    font-size: 20px;
    height: 60px;
    /* line-height: 60px; */
  }
  
  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* 调整此处的数值以改变行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media only screen and (max-width: 600px) {
    #loadingIndicator {
      height: 100px;
    }
  
    .panel.big {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .big-goods-container > div {
      width: calc(50% - 10px);
    }
    .spic-wrap {
      width: 100%;
      height: 110px;
    }
    .spic-wrap > img {
      width: 100%;
    }
  
    .products-list {
      flex-direction: column;
      width: 100vw;
      gap: 12px;
      box-sizing: border-box;
      padding: 20px 12px;
      margin: 0 auto;
    }
    .panel {
      max-width: 100%;
      box-sizing: border-box;
      flex: 0 0 120px;
      height: 150px;
      padding: 12px;
      flex-direction: row;
    }
    .goods-title {
      padding-top: 0;
      padding-right: 0;
      flex: 1 0 0;
    }
    .pic-wrap {
      display: flex;
      flex-direction: column;
      height: 100px;
      flex: 0 0 100px;
      border-radius: 6px;
    }
    .pic-wrap > img {
      width: 100px;
      height: 100px;
    }
    .feature-icon {
      display: none;
    }
  
    .red-tip {
      font-size: 10px;
      padding: 3px;
      left: -2px;
    }
  
    .red-tip::after {
      content: "";
      background-size: cover;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 2px;
      height: 2px;
    }
    .panel-mobile-right {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }
    .icon-btn {
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .bottom-bar {
      padding-right: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .goods-btn-list .icon-btn {
      border-radius: 30px;
      border: 1px solid #aaa;
    }
  }
  